/* @font-face {
  font-family: "AzoSans";
  src: local("AzoSans"), url(./fonts/AzoSans.ttf) format("ttf");
} */
@import url("https://use.typekit.net/jtv4yqi.css");
@import url("https://fonts.cdnfonts.com/css/cryogenix");

body {
  overflow-x: hidden;
  width: 100vw;
}

#root {
  overflow-x: hidden;
}

.App {
  text-align: center;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: black;
}

::-webkit-scrollbar-thumb {
  background: #58805a;
}

.countdown-grid {
  text-align: center;
  margin: auto;
}

.countdown-item {
  padding: 25px;
}

.countdown-border-right {
  border-right: solid 1px rgba(255, 255, 255, 0.5);
}

.welcome-box {
  /* display: flex;
  justify-content: flex-end;
  border-radius: 75px; 
  padding: 2% 4%;
  /* text-align: left;
  position: relative; */
  display: block;
  text-align: center;
}

.welcome-img {
  height: 50%;
  width: 50%;
  /* width: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  top: auto;
  transform: translateX(-6.5%); */
}

.welcome-content {
  /* width: 60%; */
}

.hero-minter {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  margin-top: 30px;
  z-index: 100000000;
  bottom: 13%;
  /* z-index: 1000; */
}

h2 {
  font-size: 50px;
  text-transform: uppercase;
  margin-bottom: 3%;
  letter-spacing: 1px;
  font-family: azo-sans-uber, sans-serif;
  font-weight: 400;
  font-style: normal;
}

p {
  font-family: bicyclette, sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 2px;
}

h3 {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: azo-sans-uber, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 10px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.timeline {
  height: 200px;
  width: 5px;
  background-color: #e5e5e5;
  margin-left: 13px;
}

.stub1 {
  line-height: 300px;
  font-size: 24px;
  background-color: #eae4e4;
}

.stub2 {
  height: 1000px;
}

.circle {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  background-color: #e5e5e5;
}

.circleWrapper {
  position: relative;
}

.message {
  position: absolute;
  top: 20%;
  /* left: 50%; */
  left: 50px;
  min-width: 150px;
  font-weight: bold;
  max-width: 80%;
}

.container {
  margin: 10% 0;
}

.connect-button {
  font-size: 20px !important;
  text-transform: uppercase;
  letter-spacing: 1.5px !important;
  font-family: azo-sans-uber, sans-serif !important;
  font-weight: 400 !important;
  font-style: normal;
  transition: letter-spacing 0.2s ease-in-out !important;
  color: white !important;
  background: black !important;
  min-width: 125px !important;
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 4px;
  z-index: 1000;
}

.connect-button span {
  justify-content: center;
}

h1 {
  font-size: 120px;
  text-transform: uppercase;
  margin-bottom: 3%;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: azo-sans-uber, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}

.MuiTypography-h6 {
  letter-spacing: 1px !important;
  font-family: azo-sans-uber, sans-serif !important;
  font-weight: 400;
  font-size: 18px !important;
  text-transform: uppercase;
}

.makeStyles-root-1 .MuiDialogContent-root .MuiListItem-root .MuiButton-root {
  font-family: bicyclette, sans-serif !important;
  font-style: normal;
  font-size: 16px !important;
  font-weight: 100 !important;
  letter-spacing: 1px !important;
}

.team-item-container {
  text-align: center;
  margin: 20px 0px !important;
}

.team-img {
  border: 5px solid #58805a;
  border-radius: 65px;
  width: 90%;
  height: auto;
}

.team-desc {
  text-align: center;
}

.team-desc p {
  font-size: 25px;
  margin: 0px !important;
}

.team-desc h3 {
  font-size: 30px;
  margin-bottom: 0px !important;
}

.countdown-value {
  font-size: 35px;
  margin: 0px !important;
  font-weight: 700;
}

.countdown-text {
  font-size: 16px;
  margin: 0px !important;
  font-weight: 700;
  color: #cdcdcd;
}

.navbar {
  position: fixed;
  z-index: 10000;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}

.drawer {
  background: black !important;
  color: white !important;
  padding-top: 150px;
  width: 400px;
  text-align: center;
}

.drawer-item {
  display: flex !important;
  justify-content: center !important;
}

.drawer-item h3 {
  letter-spacing: 1.5px;
  transition: letter-spacing 0.2s ease-in-out !important;
}

.footer-box {
  text-align: center;
}

.footer-socials {
  display: flex;
  justify-content: center;
}

.footer-container {
  background-color: #58805a;
  padding: 10% 0px;
  margin: 0 !important;
  max-width: none !important;
}

.footer-socials img {
  margin: 10px;
  height: 45px;
  cursor: pointer;
}

.drawer-item h3 a {
  color: inherit;
  text-decoration: inherit;
}

.accordion {
  /* background-color: rgba(255, 255, 255, 0.05) !important; */
  background-color: #58805a !important;
  color: white !important;
  border-radius: 25px !important;
  margin-bottom: 25px;
}

@media (max-width: 750px) {
  h1 {
    font-size: 60px !important;
  }

  #roadmap {
    margin-bottom: 50% !important;
  }

  .connect-button {
    font-size: 15px !important;
  }

  /* .welcome-img {
    display: none;
  } */

  .welcome-content {
    width: 80%;
    margin: auto;
  }

  .welcome-box {
    justify-self: center !important;
    /* padding: 25px; */
  }

  .welcome-content h2,
  .welcome-content p {
    text-align: center;
  }

  h2 {
    font-size: 25px !important;
  }

  .drawer {
    width: 100vw !important;
  }

  .timeline {
    height: 350px;
  }

  .heroAttr,
  .heroAttr2,
  .heroAttr3,
  .heroApe {
    height: auto !important;
    width: 100%;
  }

  .countdown-container {
    margin-top: 70px !important;
    max-width: 95% !important;
    margin: auto;
  }

  .table-row,
  .table-body,
  .table-cell {
    display: block !important;
    text-align: left !important;
  }

  .table-row {
    border-top: 1px solid !important;
  }

  .table-cell {
    border: none !important;
  }

  .table-cell-desc {
    padding-top: 0 !important;
  }

  .table-cell-desc p {
    margin-top: 0px !important;
  }

  .logo-text {
    display: none;
  }
}

.gallery-container {
  display: flex;
  position: relative;
}

.gallery-translate {
  transform: translateX(-160%) !important;
}

/* #gallery-row1 {
  transform: "-100%";
} */

/* [data-aos="gallery-animation"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
  }

  @media screen and (min-width: 768px) {
    transform: translateX(100px);

    &.aos-animate {
      transform: translateX(0);
    }
  }
} */

.contract-btn {
  font-size: 18px;
  cursor: pointer;
}

[data-aos="gallery-animation"] {
  opacity: 0;
  transition-property: transform, opacity;
}
[data-aos="gallery-animation"].aos-animate {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  [data-aos="gallery-animation"] {
    transform: translateX(100px);
  }
  [data-aos="gallery-animation"].aos-animate {
    transform: translateX(0);
  }

  p {
    font-size: 25px;
  }
}

.gallery-img {
  height: 300px;
  margin: 20px;
  border-radius: 50px;
  border: solid;
}

@media (max-width: 450px) {
  h1 {
    font-size: 45px !important;
  }

  h3 {
    font-size: 20px !important;
  }

  .MuiAlert-message h3 {
    font-size: 12px !important;
  }

  #roadmap {
    margin-bottom: 150% !important;
  }

  .minter-navbar {
    display: none;
  }

  .welcome-content {
    width: 100%;
    margin: auto;
  }

  .gallery-translate {
    transform: translateX(-300%) !important;
  }

  /* .countdown {
    width: 110% !important;
  } */

  .countdown-value {
    font-size: 20px;
  }

  .countdown-text {
    font-size: 10px;
  }

  .welcome-img {
    height: 250px;
    width: 250px;
  }

  .contract-btn {
    font-size: 12px;
  }

  .countdown-item {
    padding: 15px !important;
  }

  .hero-minter {
    bottom: 50% !important;
    white-space: nowrap !important;
  }
}

@media (min-width: 450px) {
  .connect-button:hover {
    letter-spacing: 0.2em !important;
  }

  .drawer-item h3:hover {
    letter-spacing: 0.2em !important;
  }
}

.logo {
  height: 65px;
  margin: 15px;
}

.heroAttr,
.heroAttr2,
.heroAttr3,
.heroApe {
  position: absolute;
  height: 55%;
  left: 0;
  right: 0;
  margin: auto;
  /* transform: translateX(-50%); */
  bottom: 0;
  z-index: 2;
}

/* .heroAttr{
  transform: tran
} */

.float {
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

.countdown-container {
  text-align: center;
  margin-top: 40px;
  width: 75%;
  max-width: 700px;
}

.table-cell {
  color: white !important;
}

.title-container {
  padding-bottom: 40px;
}

.logo-text-footer {
  font-family: "cryogenix", sans-serif;
  font-size: 40px;
}

.logo-text {
  font-family: "cryogenix", sans-serif;
  font-size: 30px;
  white-space: nowrap;
}

.discord-btn {
  background: black;
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 4px;
}

.terms-btn {
  cursor: pointer;
}

.welcome-img-container {
  -webkit-mask: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(255, 255, 255, 0) 0%,
    rgba(2, 2, 2, 1) 30%
  );
}

.accordion-details {
  margin-top: 0px !important;
}

.loader {
  width: 100vw;
  height: 100vh;
  display: flex !important;
  justify-content: center !important;
  margin: 0px !important;
  text-align: center !important;
  max-width: none !important;
  align-items: center !important;
  position: fixed;
  background-color: black;
  z-index: 1000000001;
}
.logo-text-loader span {
  display: inline-block;
  font-family: "cryogenix", sans-serif;
  font-size: 30px;
}

.loader-text {
  /* overflow: hidden; */
  padding-top: 10px;
}

.incr-decr {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  display: flex;
  margin-bottom: 30px;
  justify-content: center;
}

.mint-button {
  font-size: 25px !important;
}

.alert {
  position: fixed !important;
  z-index: 999999999999999 !important;
  bottom: 10% !important;
  width: 90% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  max-width: 500px !important;
}

.alert .MuiAlert-icon {
  align-items: center;
}

.account-container {
  text-align: center;
}

.verify-button {
  border: 1px solid white !important;
  margin-top: 30px !important;
}

.verify-container {
  min-height: 100vh;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10% 0 !important;
  margin: 0 !important;
  max-width: none !important;
}

.video {
  max-width: 90%;
}

.profile-img {
  border: 5px solid #58805a;
  border-radius: 65px;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.breeding-img {
  border: 5px solid #58805a;
  border-radius: 65px;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  cursor: pointer;
  object-fit: cover;
}

.breeding-not-ready {
  border: 5px solid #d36013 !important;
  cursor: default !important;
  pointer-events: none !important;
}

.popup-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  display: flex;
  z-index: 10001;
}

.popup-container {
  max-width: 90%;
  width: 90%;
  background-color: black;
  border-radius: 8px;
  margin: auto;
  border: 2px solid rgb(202, 202, 202);
}

.popup-title {
  color: white !important;
}

.popup-title-container {
  border-bottom: 2px solid rgb(194, 192, 192);
  margin: 10px;
  align-content: space-between;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.close-popup {
  color: white;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: end;
}

.breed-btn-container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.breed-btn {
  font-size: 30px !important;
  border: 1px solid white !important;
}

.modal-btn {
  border: 1px solid white !important;
}

.MuiPaginationItem-root {
  color: white !important;
  letter-spacing: 1px;
  font-family: azo-sans-uber, sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.MuiPagination-ul {
  margin: 10px auto !important;
  justify-content: center;
}

.breeding-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.breeding-add {
  font-size: 50px !important;
  position: absolute;
  cursor: pointer;
}

.loadmore {
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.75);
}

.collection-container {
  margin: 60px 0px;
}

.loading-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 350px;
}

.breed-disable {
  cursor: not-allowed !important;
}

.breeding-time-container p {
  font-size: 15px !important;
}

.border-btn {
  border: 1px solid white;
}

.loading {
  color: rgba(255, 255, 255, 0.75);
}

.breeding-subtext {
  text-align: center;
  margin-bottom: 80px;
}

.top40 {
  margin-top: 40px;
}

.lookup-input label {
  color: white !important;
}

.lookup-input div fieldset {
  border: rgba(255, 255, 255, 0.87) solid 1px !important;
}

.lookup-input-container {
  display: flex;
  justify-content: center;
}

.lookup-input div input {
  color: white !important;
}

@media (max-width: 1200px) {
  .container-margin-top {
    margin-top: 150px;
  }
}

.modal-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 999999;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

.modal-background > div {
  background: black;
  padding: 35px;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.close-modal {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  margin: 10px;
}

.modal-img {
  max-width: 200px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 5px solid #58805a;
}

.pointer {
  cursor: pointer;
}
